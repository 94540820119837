<template>
  <select v-model="currentProduct" class="product-select">
    <option v-for="type in productSelectItems" :key="type">
      {{ type }}
    </option>
  </select>
</template>

<script>
import PRODUCT_CONFIGS from '@/productConfigs';
import ProductType from '@/statics/productType';
import { LOCAL_PRODUCT_STORAGE } from '@/statics/storageKeys';
import { getProductTypeFromSubdomain } from '@/helper/productResolver';

export default {
  name: 'LocalProductSelect',

  computed: {
    productSelectItems() {
      return PRODUCT_CONFIGS.map((product) => product.type);
    },
    currentProduct: {
      get() {
        return this.productType;
      },
      set(value) {
        const useProductTypeFromSubdomain = !!getProductTypeFromSubdomain();

        if (useProductTypeFromSubdomain) {
          window.location.hostname = this.getHostnameFromProductType(value);
          return;
        }

        localStorage.setItem(LOCAL_PRODUCT_STORAGE, value);
        window.location.reload();
      }
    }
  },

  methods: {
    getHostnameFromProductType(productType) {
      switch (productType) {
        case ProductType.DV_FACTORING:
          return process.env.VUE_APP_FACTORING_HOSTNAME;
        case ProductType.DV_BILLING:
          return process.env.VUE_APP_BILLING_HOSTNAME;
        case ProductType.FW_FACTORING:
          return process.env.VUE_APP_TEAMFACTOR_HOSTNAME;
        case ProductType.DAA_BILLING:
          return process.env.VUE_APP_DAA_HOSTNAME;
        case ProductType.DAB_BILLING:
          return process.env.VUE_APP_DAB_HOSTNAME;
        case ProductType.FAS_BILLING:
          return process.env.VUE_APP_FAS_HOSTNAME;
        case ProductType.VHV_FACTORING:
          return process.env.VUE_APP_VHV_HOSTNAME;
        case ProductType.TPF_FACTORING:
          return process.env.VUE_APP_TPF_HOSTNAME;
        default:
          throw new Error(`Unknown product type ${productType}`);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.product-select {
  cursor: pointer;
  text-align: center;
}
</style>
